import { makeStyles } from '@material-ui/core/styles';

export const privacyPolicyStyles = makeStyles(() => ({
  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: '#344266',
  },
  box: {
    minHeight: '300px',
    width: 'auto',
    margin: '0px 18% 0px 18%',
    paddingTop: '60px',
    // backgroundColor: '#344266',
  },
  h1: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    marginBottom: '1.45rem',
    color: 'inherit',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,\n      Helvetica Neue, sans-serif',
    fontWeight: 'bold',
    textRendering: 'optimizeLegibility',
    fontSize: '2.25rem',
    lineHeight: 1.1,
  },
  h2: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    marginBottom: '1.45rem',
    color: 'inherit',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,\n      Helvetica Neue, sans-serif',
    fontWeight: 'bold',
    textRendering: 'optimizeLegibility',
    fontSize: '1.62671rem',
    lineHeight: 1.1,
  },
  h3: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    marginBottom: '1.45rem',
    color: 'inherit',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,\n      Helvetica Neue, sans-serif',
    fontWeight: 'bold',
    textRendering: 'optimizeLegibility',
    fontSize: '1.38316rem',
    lineHeight: 1.1,
  },
  h4: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    marginBottom: '1.45rem',
    color: 'inherit',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,\n      Helvetica Neue, sans-serif',
    fontWeight: 'bold',
    textRendering: 'optimizeLegibility',
    fontSize: '1rem',
    lineHeight: 1.1,
  },
  h5: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    marginBottom: '1.45rem',
    color: 'inherit',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,\n      Helvetica Neue, sans-serif',
    fontWeight: 'bold',
    textRendering: 'optimizeLegibility',
    fontSize: '0.85028rem',
    lineHeight: 1.1,
  },
  h6: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    marginBottom: '1.45rem',
    color: 'inherit',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,\n      Helvetica Neue, sans-serif',
    fontWeight: 'bold',
    textRendering: 'optimizeLegibility',
    fontSize: '0.78405rem',
    lineHeight: 1.1,
  },
  boxImagesNoContent: {
    minHeight: '30px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
  },
}));
