import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { privacyPolicyStyles } from './styles';

const PrivacyPolicy = () => {
  const { backgroundImagePrices } = useStaticQuery(
    graphql`
      query {
        backgroundImagePrices: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );
  const pluginImage = getImage(backgroundImagePrices);
  // const PrivacyPolicy = () => {
  const classes = privacyPolicyStyles();
  // const { t } = useTranslation();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesNoContent}>
              <Typography className={classes.siteTitle}>{/* {t('trans.privacyPolicyHead')} */}</Typography>
            </Box>
          </Grid>
        </Grid>
      </BgImage>
      <Box className={classes.box}>
        <h1 className="adsimple-321244783">Datenschutzhinweise</h1>
        <h2 className="adsimple-321244783">Datenschutz</h2>
        <p>
          Wir haben diesen Datenschutzhinweis (Fassung 27.12.2020-321244783) verfasst, um Ihnen gemäß der Vorgaben der{' '}
          <a
            className="adsimple-321244783"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </a>{' '}
          zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten
          Sie als Besucher dieser Webseite haben.
        </p>
        <p>
          Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
          Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
        </p>
        <h2 className="adsimple-321244783">Automatische Datenspeicherung</h2>
        <p>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
          auch auf dieser Webseite.
        </p>
        <p>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese
          Webseite gespeichert ist) automatisch Daten wie
        </p>
        <ul className="adsimple-321244783">
          <li className="adsimple-321244783">die Adresse (URL) der aufgerufenen Webseite</li>
          <li className="adsimple-321244783">Browser und Browserversion</li>
          <li className="adsimple-321244783">das verwendete Betriebssystem</li>
          <li className="adsimple-321244783">die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
          <li className="adsimple-321244783">
            den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
          </li>
          <li className="adsimple-321244783">Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben
          diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
          Verhalten eingesehen werden.
        </p>
        <h2 className="adsimple-321244783">Cookies</h2>
        <p>
          Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
          <br />
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
          Datenschutzerklärung besser verstehen.
        </p>
        <h3 className="adsimple-321244783">Was genau sind Cookies?</h3>
        <p>
          Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
          Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
        </p>
        <p>
          Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden
          Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
          gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
          Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers,
          untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen
          zusätzlich ein oder mehrere Attribute angegeben werden.
        </p>
        <p>
          Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
          Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
          Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre
          gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie
          beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
        </p>
        <p>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
          unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt.
          Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
          Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
          enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres
          PCs zugreifen.
        </p>
        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
        <ul className="adsimple-321244783">
          <li className="adsimple-321244783">Name: _ga</li>
          <li className="adsimple-321244783">Ablaufzeit: 2 Jahre</li>
          <li className="adsimple-321244783">Verwendung: Unterscheidung der Webseitenbesucher</li>
          <li className="adsimple-321244783">Beispielhafter Wert: GA1.2.1326744211.152321244783</li>
        </ul>
        <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
        <ul className="adsimple-321244783">
          <li className="adsimple-321244783">Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
          <li className="adsimple-321244783">Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
          <li className="adsimple-321244783">Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>
        </ul>
        <h3 className="adsimple-321244783">Welche Arten von Cookies gibt es?</h3>
        <p>
          Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der
          folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
          verschiedenen Arten von HTTP-Cookies eingehen.
        </p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
          <strong className="adsimple-321244783">
            Unbedingt notwendige Cookies
            <br />
          </strong>
          Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es
          diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später
          erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
          Browserfenster schließt.
        </p>
        <p>
          <strong className="adsimple-321244783">
            Funktionelle Cookies
            <br />
          </strong>
          Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem
          werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
          gemessen.
        </p>
        <p>
          <strong className="adsimple-321244783">
            Zielorientierte Cookies
            <br />
          </strong>
          Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
          Schriftgrößen oder Formulardaten gespeichert.
        </p>
        <p>
          <strong className="adsimple-321244783">
            Werbe-Cookies
            <br />
          </strong>
          Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung
          zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
        </p>
        <p>
          Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie
          zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
        </p>
        <h3 className="adsimple-321244783">Wie kann ich Cookies löschen?</h3>
        <p>
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
          Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder
          zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
          zulassen.
        </p>
        <p>
          Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
          Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.google.com/chrome/answer/95647?tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
          informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie
          das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen
          die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren
          Chrome&#8221; im Falle eines Chrome Browsers oder tauschen das Wort &#8220;Chrome&#8221; gegen den Namen Ihres
          Browsers, z.B. Edge, Firefox, Safari aus.
        </p>
        <h3 className="adsimple-321244783">Wie sieht es mit meinem Datenschutz aus?</h3>
        <p>
          Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
          eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche
          Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
          umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
          Telemediengesetzes (TMG).
        </p>
        <p>
          Wenn Sie mehr über Cookies wissen möchten und technischen Dokumentationen nicht scheuen, empfehlen wir
          <a
            className="adsimple-321244783"
            href="https://tools.ietf.org/html/rfc6265"
            target="_blank"
            rel="nofollow noreferrer"
          >
            https://tools.ietf.org/html/rfc6265
          </a>
          , dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management
          Mechanism&#8221;.
        </p>
        <h2 className="adsimple-321244783">Speicherung persönlicher Daten</h2>
        <p>
          Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
          E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
          Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen
          Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt
          ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
          Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen,
          dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        </p>
        <p>
          Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits dieser Webseite &#8211; können wir
          keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
          niemals unverschlüsselt per E-Mail zu übermitteln.
        </p>
        <p>
          Die Rechtsgrundlage besteht nach{' '}
          <a
            className="adsimple-321244783"
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Artikel 6 Absatz 1 a DSGVO
          </a>{' '}
          (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen
          eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen &#8211; eine formlose E-Mail
          reicht aus, Sie finden unsere Kontaktdaten im Impressum.
        </p>
        <h2 className="adsimple-321244783">Rechte laut Datenschutzgrundverordnung</h2>
        <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
        <ul className="adsimple-321244783">
          <li className="adsimple-321244783">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
          <li className="adsimple-321244783">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
          <li className="adsimple-321244783">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
          <li className="adsimple-321244783">
            Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung
            personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
          </li>
          <li className="adsimple-321244783">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
          <li className="adsimple-321244783">Widerspruchsrecht (Artikel 21 DSGVO)</li>
          <li className="adsimple-321244783">
            Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
            beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
          </li>
        </ul>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
          datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die{' '}
          <a className="321244783" href="https://www.bfdi.bund.de" target="_blank" rel="noreferrer">
            Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)
          </a>{' '}
          wenden.
        </p>
        <h2 className="adsimple-321244783">Auswertung des Besucherverhaltens</h2>
        <p>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser
          Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem
          Verhalten auf dieser Website nicht auf Ihre Person schließen.
        </p>
        <p>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden
          Datenschutzerklärung.
        </p>
        <h2 className="adsimple-321244783">TLS-Verschlüsselung mit https</h2>
        <p>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung{' '}
          <a
            className="adsimple-321244783"
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321244783"
            target="_blank"
            rel="noreferrer"
          >
            Artikel 25 Absatz 1 DSGVO
          </a>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren
          Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die
          Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der
          Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
        </p>
        <h2 className="adsimple-321244783">Google Fonts Datenschutzerklärung</h2>
        <p>
          Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google
          Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin
          4, Irland) für alle Google-Dienste verantwortlich.
        </p>
        <p>
          Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
          Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden
          über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen
          nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben,
          brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an
          Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten
          Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im
          Detail ansehen.
        </p>
        <h3 className="adsimple-321244783">Was sind Google Fonts?</h3>
        <p>
          Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die
          <a className="adsimple-321244783" href="https://de.wikipedia.org/wiki/Google_LLC?tid=321244783">
            Google
          </a>
          seinen Nutzern kostenlos zu Verfügung stellt.
        </p>
        <p>
          Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
          Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
        </p>
        <h3 className="adsimple-321244783">Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
        <p>
          Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren
          eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
          halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist
          speziell für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt
          die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
          Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen
          Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch
          verzerren. Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts keine
          plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla
          Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen,
          einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir
          unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.
        </p>
        <h3 className="adsimple-321244783">Welche Daten werden von Google gespeichert?</h3>
        <p>
          Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
          externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
          IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
          Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften
          nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als
          Datenübermittler im Softwarebereich.
        </p>
        <p>
          Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
          gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
          veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google
          auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese
          Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das
          Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.
        </p>
        <p>
          Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
          Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers
          automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar
          feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
        </p>
        <h3 className="adsimple-321244783">Wie lange und wo werden die Daten gespeichert?</h3>
        <p>
          Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU
          angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
          Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer
          Webseite ändern kann.
        </p>
        <p>
          Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
          Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
          werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten
          Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung
          von Sprache zu erhöhen und das Design zu verbessern.
        </p>
        <h3 className="adsimple-321244783">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>
          Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
          werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen
          Sie den Google-Support auf{' '}
          <a className="adsimple-321244783" href="https://support.google.com/?hl=de&amp;tid=321244783">
            https://support.google.com/?hl=de&amp;tid=321244783
          </a>{' '}
          kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
        </p>
        <p>
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
          also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr
          zu Google Fonts und weiteren Fragen finden Sie auf{' '}
          <a className="adsimple-321244783" href="https://developers.google.com/fonts/faq?tid=321244783">
            https://developers.google.com/fonts/faq?tid=321244783
          </a>
          . Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen
          über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
          Informationen über gespeicherten Daten zu bekommen.
        </p>
        <p>
          Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
          auf{' '}
          <a className="adsimple-321244783" href="https://policies.google.com/privacy?hl=de&amp;tid=321244783">
            https://www.google.com/intl/de/policies/privacy/
          </a>{' '}
          nachlesen.
        </p>
        <h2 className="adsimple-321244783">Google Fonts Lokal Datenschutzerklärung</h2>
        <p>
          Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das
          Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben
          die Google-Schriftarten lokal, d.h. auf unserem Webserver &#8211; nicht auf den Servern von Google &#8211;
          eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder
          Speicherung.
        </p>
        <h3 className="adsimple-321244783">Was sind Google Fonts?</h3>
        <p>
          Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis
          mit über 800 Schriftarten, die{' '}
          <a className="adsimple-321244783" href="https://de.wikipedia.org/wiki/Google_LLC?tid=321244783">
            Google
          </a>
          kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server
          hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir
          die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden
          keine Daten an Google Fonts weiter.
        </p>
        <p>
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
          also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr
          zu Google Fonts und weiteren Fragen finden Sie auf{' '}
          <a className="adsimple-321244783" href="https://developers.google.com/fonts/faq?tid=321244783">
            https://developers.google.com/fonts/faq?tid=321244783
          </a>
          .
        </p>
        <h2 className="adsimple-321244783">Eingebettete Social Media Elemente Datenschutzerklärung</h2>
        <p>
          Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.
          <br />
          Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen
          Social Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
          <br />
          Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese
          mit Ihren Daten umgehen:
        </p>
        <ul className="adsimple-321244783">
          <li className="adsimple-321244783">
            Instagram-Datenschutzrichtlinie:{' '}
            <a
              className="adsimple-321244783"
              href="https://help.instagram.com/519522125107875?tid=321244783"
              target="_blank"
              rel="noreferrer"
            >
              https://help.instagram.com/519522125107875
            </a>
          </li>
          <li className="adsimple-321244783">
            Für YouTube gilt die Google Datenschutzerklärung:{' '}
            <a
              className="adsimple-321244783"
              href="https://policies.google.com/privacy?hl=de&amp;tid=321244783"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy?hl=de
            </a>
          </li>
          <li className="adsimple-321244783">
            Facebook-Datenrichtline:
            <a
              className="adsimple-321244783"
              href="https://www.facebook.com/about/privacy?tid=321244783"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/about/privacy
            </a>
          </li>
          <li className="adsimple-321244783">
            Twitter Datenschutzrichtlinie:
            <a
              className="adsimple-321244783"
              href="https://twitter.com/de/privacy?tid=321244783"
              target="_blank"
              rel="noreferrer"
            >
              https://twitter.com/de/privacy
            </a>
          </li>
        </ul>
        <h2 className="adsimple-321244783">YouTube Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf
          unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist.
          Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf
          unserer Website eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser
          automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten
          übertragen. Für die gesamte Datenverarbeitung im europäischen Raum ist Google Ireland Limited (Gordon House,
          Barrow Street Dublin 4, Irland) verantwortlich.
        </p>
        <p>
          Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
          eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.
        </p>
        <h3 className="adsimple-321244783">Was ist YouTube?</h3>
        <p>
          Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die
          letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf
          unserer Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite
          eingebaut haben.
        </p>
        <h3 className="adsimple-321244783">Warum verwenden wir YouTube-Videos auf unserer Webseite?</h3>
        <p>
          YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die
          bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei
          nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern
          weiteren hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die
          eingebetteten Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google –
          dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote
          interessieren.
        </p>
        <h3 className="adsimple-321244783">Welche Daten werden von YouTube gespeichert?</h3>
        <p>
          Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein
          Cookie, das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann
          YouTube Ihre Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen
          Daten wie Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp,
          Bildschirmauflösung oder Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das
          Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.
        </p>
        <p>
          Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit
          einer eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre
          bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden, da
          weniger Cookies gesetzt werden.
        </p>
        <p>
          In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits
          Cookies, die ohne angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit
          angemeldetem Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die
          Userdaten immer von den Interaktionen auf YouTube abhängen.
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> YSC
          <br />
          <strong className="adsimple-321244783">Wert:</strong> b9-CV6ojI5Y321244783-1
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige
          ID, um Statistiken des gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach Sitzungsende
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> PREF
          <br />
          <strong className="adsimple-321244783">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre
          eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 8 Monaten
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> GPS
          <br />
          <strong className="adsimple-321244783">Wert:</strong> 1
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID
          auf mobilen Geräten, um den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 30 Minuten
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-321244783">Wert:</strong> 95Chz8bagyU
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des
          Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 8 Monaten
        </p>
        <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> APISID
          <br />
          <strong className="adsimple-321244783">Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7321244783-
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil
          über Ihre Interessen zu erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> CONSENT
          <br />
          <strong className="adsimple-321244783">Wert:</strong> YES+AT.de+20150628-20-0
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Das Cookie speichert den Status der
          Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit,
          um User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum: </strong>
          nach 19 Jahren
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> HSID
          <br />
          <strong className="adsimple-321244783">Wert:</strong> AcRwpgUik9Dveht0I
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil
          über Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> LOGIN_INFO
          <br />
          <strong className="adsimple-321244783">Wert:</strong> AFmmF2swRQIhALl6aL…
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> In diesem Cookie werden Informationen über
          Ihre Login-Daten gespeichert.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> SAPISID
          <br />
          <strong className="adsimple-321244783">Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren
          Browser und Ihr Gerät eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu
          erstellen.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> SID
          <br />
          <strong className="adsimple-321244783">Wert:</strong> oQfNKjAsI321244783-
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID
          und Ihren letzten Anmeldezeitpunkt in digital signierter und verschlüsselter Form.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> SIDCC
          <br />
          <strong className="adsimple-321244783">Wert:</strong> AN0-TYuqub2JOcDTyL
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie
          Sie die Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 3 Monaten
        </p>
        <h3 className="adsimple-321244783">Wie lange und wo werden die Daten gespeichert?</h3>
        <p>
          Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten
          dieser Server befinden sich in Amerika. Unter{' '}
          <a
            className="adsimple-321244783"
            href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/about/datacenters/inside/locations/?hl=de
          </a>{' '}
          sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind
          die Daten schneller abrufbar und vor Manipulation besser geschützt.
        </p>
        <p>
          Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere
          werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit
          gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem
          Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem
          Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
          löschen.
        </p>
        <h3 className="adsimple-321244783">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>
          Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatische
          Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung &#8211;
          entweder 3 oder 18 Monate gespeichert und dann gelöscht.
        </p>
        <p>
          Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies
          von Google gelöscht bzw. deaktiviert werden. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf
          unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.google.com/chrome/answer/95647?tid=321244783"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244783"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244783"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244783"
            target="_blank"
            rel="noopener noreferrer"
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            className="adsimple-321244783"
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244783"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge: Löschen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
          informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie
          es erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame
          Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die
          Datenschutzerklärung unter{' '}
          <a
            className="adsimple-321244783"
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de.
          </a>
        </p>
        <h2 className="adsimple-321244783">YouTube Abonnieren Button Datenschutzerklärung</h2>
        <p>
          Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl. „Subscribe-Button“) eingebaut. Sie
          erkennen den Button meist am klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in weißer Schrift
          die Wörter „Abonnieren“ oder „YouTube“ und links davon das weiße „Play-Symbol“. Der Button kann aber auch in
          einem anderen Design dargestellt sein.
        </p>
        <p>
          Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante oder spannende Videos. Mit dem eingebauten
          „Abonnieren-Button“ können Sie unseren Kanal direkt von unserer Webseite aus abonnieren und müssen nicht
          eigens die YouTube-Webseite aufrufen. Wir wollen Ihnen somit den Zugang zu unserem umfassenden Content so
          einfach wie möglich machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen speichern und verarbeiten
          kann.
        </p>
        <p>
          Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt YouTube &#8211; laut Google &#8211;
          mindestens ein Cookie. Dieses Cookie speichert Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren
          Browser, Ihren ungefähren Standort und Ihre voreingestellte Sprache kann YouTube so erfahren. Bei unserem Test
          wurden folgende vier Cookies gesetzt, ohne bei YouTube angemeldet zu sein:
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> YSC
          <br />
          <strong className="adsimple-321244783">Wert:</strong> b9-CV6ojI5321244783Y
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige
          ID, um Statistiken des gesehenen Videos zu speichern.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach Sitzungsende
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> PREF
          <br />
          <strong className="adsimple-321244783">Wert:</strong> f1=50000000
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre
          eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 8 Monate
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> GPS
          <br />
          <strong className="adsimple-321244783">Wert:</strong> 1
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID
          auf mobilen Geräten, um den GPS-Standort zu tracken.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 30 Minuten
        </p>
        <p>
          <strong className="adsimple-321244783">Name:</strong> VISITOR_INFO1_LIVE
          <br />
          <strong className="adsimple-321244783">Wert:</strong> 32124478395Chz8bagyU
          <br />
          <strong className="adsimple-321244783">Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des
          Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.
          <br />
          <strong className="adsimple-321244783">Ablaufdatum:</strong> nach 8 Monaten
        </p>
        <p>
          <strong className="adsimple-321244783">Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt und
          können nicht den Anspruch auf Vollständigkeit erheben.
        </p>
        <p>
          Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele Ihrer Handlungen/Interaktionen auf unserer
          Webseite mit Hilfe von Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt dadurch zum
          Beispiel Informationen wie lange Sie auf unserer Seite surfen, welchen Browsertyp Sie verwenden, welche
          Bildschirmauflösung Sie bevorzugen oder welche Handlungen Sie ausführen.
        </p>
        <p>
          YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und Angebote zu verbessern, zum
          anderen um Analysen und Statistiken für Werbetreibende (die Google Ads verwenden) bereitzustellen.
        </p>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
